import { React, useEffect, useState, useCallback, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import axios from "axios";
// import request from "request";

import "./App.css";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("Phone number is required"),
  name: Yup.string().required("Name is required"),
  secondaryPhoneNumber: Yup.string().matches(
    /^\d{10}$/,
    "Phone number must be exactly 10 digits"
  ),
  email: Yup.string().email("Invalid email format"),
  address: Yup.string().required("Address is required"),
  pincode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be exactly 6 digits")
    .required("Pincode is required"),
  paymentMode: Yup.string().required("Payment mode is required"),
  orderBy: Yup.string().required("Order by is required"),
  product1: Yup.string().required("Atleast 1 product is required"),
  product1Quantity: Yup.number()
    .required("Product 1 quantity is required")
    .positive()
    .integer(),
  product2: Yup.string(),
  product2Quantity: Yup.number().positive().integer(),
  product3: Yup.string(),
  product3Quantity: Yup.number().positive().integer(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
});
const firebaseinit = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "ebook-campaigns.firebaseapp.com",
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  return initializeApp(firebaseConfig);
};
const App = () => {
  const [users, setUsers] = useState(null);
  const [courier, setCourier] = useState(null);
  const [latestOrder, setLatestOrder] = useState(null);
  const [totalOrders, setTotalOrders] = useState(null);
  const [edd, setEDD] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingEDD, setLoadingEDD] = useState(false);
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [state, setState] = useState(false);
  const [total, setTotal] = useState(0);
  const [over1000, setOver1000] = useState(false);
  const [prepaidTotal, setPrepaidTotal] = useState(0);
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderError, setOrderError] = useState(null);
  // const [token, setToken] = useState(null);
  firebaseinit();

  const initialValues = {
    name: "",
    phoneNumber: "",
    email: "",
    secondaryPhoneNumber: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    paymentMode: "cod",
    orderBy: "",
    couponCode: "",
    product1: "",
    product1Quantity: "",
    product2: "",
    product2Quantity: "",
    product3: "",
    product3Quantity: "",
  };

  const agentNames = {
    ritu: "Ritu",
    manpreet: "Manpreet",
    sakshi: "Sakshi",
    reet: "Reet",
    shruti: "Shruti",
    interakt: "Interakt",
    customer: "Customer",
  };

  const stateCodes = {
    "ANDAMAN & NICOBAR": "AN",
    "ANDAMAN AND NICOBAR ISLANDS": "AN",
    "ANDHRA PRADESH": "AP",
    "ARUNACHAL PRADESH": "AR",
    ASSAM: "AS",
    BIHAR: "BR",
    CHANDIGARH: "CH",
    CHHATTISGARH: "CT",
    "DADRA & NAGAR HAVELI": "DN",
    "DAMAN & DIU": "DD",
    DELHI: "DL",
    GOA: "GA",
    GUJARAT: "GJ",
    HARYANA: "HR",
    "HIMACHAL PRADESH": "HP",
    "JAMMU & KASHMIR": "JK",
    "JAMMU AND KASHMIR": "JK",
    JHARKHAND: "JH",
    KARNATAKA: "KA",
    KERALA: "KL",
    LADAKH: "LA",
    LAKSHADWEEP: "LD",
    "MADHYA PRADESH": "MP",
    MAHARASHTRA: "MH",
    MANIPUR: "MN",
    MEGHALAYA: "ML",
    MIZORAM: "MZ",
    NAGALAND: "NL",
    ODISHA: "OR",
    PONDICHERRY: "PY",
    PUDUCHERRY: "PY",
    PUNJAB: "PB",
    RAJASTHAN: "RJ",
    SIKKIM: "SK",
    "TAMIL NADU": "TN",
    TELANGANA: "TG",
    "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU": "DN",
    TRIPURA: "TR",
    "UTTAR PRADESH": "UP",
    UTTARAKHAND: "UK",
    "WEST BENGAL": "WB",
  };

  const products = useMemo(() => {
    // Calculate or initialize 'products' here
    return {
      9171: {
        name: "Prostpro - 60 Capsules - For enlarged prostate",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      9172: {
        name: "Oligo - 60 Tablets - Improves Male Fertility, Sperm Count, Size and Mobility",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      23729: {
        name: "Men's Sexual Health Combo - Azoospermia, ED, PE",
        saleprice: "949",
        price: "1598",
        tax: "12",
      },
      9168: {
        name: "Uria - 60 Capsules - Controls uric acid",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      9169: {
        name: "Calm-Hi - 60 Tablets - Controls High BP",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      9170: {
        name: "Lipipro - 60 Capsules - Controls Cholesterol",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      9173: {
        name: "Pilepro - 60 Capsules - Controls Piles & Fissures",
        saleprice: "499",
        price: "599",
        tax: "12",
      },

      // ... (previous entries)
      10194: {
        name: "Kheera Foaming Face Wash 120ml",
        saleprice: "299",
        price: "399",
        tax: "18",
      },
      10195: {
        name: "Nimbu Hand Wash 300ml",
        saleprice: "149",
        price: "299",
        tax: "18",
      },
      10196: {
        name: "Neem Aloe Vera Shampoo 300ml",
        saleprice: "349",
        price: "499",
        tax: "18",
      },
      // ... (previous entries)
      12889: {
        name: "FTS - 60 Tablets- Herbal Fat Burner",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      12890: {
        name: "Herbomine - 60 Capsules - Herbal Calcium, Iron, Zinc Supplement",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      12891: {
        name: "D-Thyrd - 60 Tablets - Useful in Thyroidism",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      // ... (remaining entries)
      12898: {
        name: "Guluconil - 60 Tablets - Helps Regulate Blood Sugar",
        saleprice: "299",
        price: "599",
        tax: "12",
      },
      13322: {
        name: "Testovridhi - 60 Capsules - Testosterone Booster For Best Physical Performance",
        saleprice: "649",
        price: "999",
        tax: "12",
      },
      16160: {
        name: "Amla Shikakai Shampoo 100ml",
        saleprice: "150",
        price: "150",
        tax: "18",
      },
      // ... (remaining entries)
      // ... (previous entries)
      17518: {
        name: "Ayurvedic Kheera Gel - 100gm",
        saleprice: "349",
        price: "549",
        tax: "18",
      },
      18123: {
        name: "Dephos - 60 Tablets - Enhance Mood, Sleep and Memory",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      // ... (remaining entries)
      18258: {
        name: "Dephos - 500ml Syrup - Enhance Mood, Sleep and Memory",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      18269: {
        name: "Dephos Combo - Big Brain Boost Package",
        saleprice: "849",
        price: "1198",
        tax: "12",
      },
      18509: {
        name: "S-Crush - 500ml Syrup - Manage Kidney Stones",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      // ... (remaining entries)
      // ... (previous entries)
      18511: {
        name: "Deliv - 500ml Syrup - Liver Tonic",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      18512: {
        name: "Dezyme - 500ml Syrup - Digestion Expert",
        saleprice: "499",
        price: "599",
        tax: "12",
      },
      18516: {
        name: "Deliv Combo - Let Liver Live Package",
        saleprice: "849",
        price: "1198",
        tax: "12",
      },
      18517: {
        name: "Dezyme Combo - Digestion Expert Package",
        saleprice: "849",
        price: "1198",
        tax: "12",
      },
    };
  }, []);

  const handleSubmit = async (values, resetForm) => {
    // Prepare the order data from the form values
    setOrderError(null);
    setLoadingOrder(true);
    setOrderNumber(false);
    const statecode = stateCodes[values.state.toUpperCase()];
    const email = values.email ? values.email : "care@dharishah.com";
    const address = values.secondaryPhoneNumber
      ? values.address + `(mob: ${values.secondaryPhoneNumber})`
      : values.address;
    const mrp1 = products[values.product1]["price"];
    const sp1 = products[values.product1]["saleprice"];
    const gst1 = products[values.product1]["tax"];
    const orderby = values.orderBy === "customer" ? "" : ":" + values.orderBy;
    const coupon = values.couponCode
      ? values.couponCode
      : over1000
      ? "100on1000" + orderby
      : orderby.split(":")[1];
    // console.log(orderby.split(":")[1]);
    const orderDetails = {
      name: values.name,
      phone: values.phoneNumber,
      address: address,
      city: values.city,
      state: statecode,
      pincode: values.pincode,
      email: email,
      payment: values.paymentMode,
      coupon: coupon,
      products: [[values.product1, values.product1Quantity, sp1, mrp1, gst1]],
    };
    if (values.product2Quantity && values.product2) {
      orderDetails.products.push([
        values.product2,
        values.product2Quantity,
        products[values.product2]["saleprice"],
        products[values.product2]["price"],
        products[values.product2]["tax"],
      ]);
    }
    if (values.product3Quantity && values.product3) {
      orderDetails.products.push([
        values.product3,
        values.product3Quantity,
        products[values.product3]["saleprice"],
        products[values.product3]["price"],
        products[values.product3]["tax"],
      ]);
    }
    axios
      .post(
        "https://za0g0ueqi0.execute-api.ap-south-1.amazonaws.com/api/singleorder",
        orderDetails
      )
      .then((response) => {
        setLoadingOrder(false);
        setOrderNumber(response.data);
        setOrderError(null);
        resetForm();
      })
      .catch((error) => {
        setLoadingOrder(false);
        console.log("Error:", error);
        setOrderError(error.response.data);
      });
    // console.log(orderDetails);
  };

  const calcTotals = useCallback(
    (p1, p2, p3, q1, q2, q3) => {
      if (p1 === "") return;
      const multiDiscount = (q) =>
        q ? (parseInt(q) === 1 ? 1 : parseInt(q) > 2 ? 0.801 : 0.901) : 0;
      const pTotal = (p, q) =>
        p && q
          ? parseInt(products[p]["saleprice"]) * parseInt(q) * multiDiscount(q)
          : 0;
      let oTotal = Math.round(pTotal(p1, q1) + pTotal(p2, q2) + pTotal(p3, q3));
      if (oTotal > 1000) {
        setOver1000(true);
        oTotal = oTotal - 100;
      } else if (oTotal === 0) {
        setOver1000(false);
        setTotal(oTotal);
        setPrepaidTotal(Math.round(oTotal * 0.9));
        return;
      } else if (oTotal < 399) {
        setOver1000(false);
        oTotal = oTotal + 150;
      }
      setTotal(oTotal);
      setPrepaidTotal(Math.round(oTotal * 0.9));
    },
    [products]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      // Handle form submission here
      handleSubmit(values, resetForm);
    },
    validationSchema,
  });

  // Handle change in the source field
  const handleSourceChange = (event) => {
    const pincode = event.target.value;
    // Implement your logic to update the target field based on the source value
    if (pincode.length === 6) {
      setLoading(true);
      setLoadingEDD(true);
      formik.setFieldValue("state", "");
      formik.setFieldValue("city", "");
      axios
        .get(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const firstResult = response.data[0];
            console.log(firstResult);
            if (firstResult.Status === "Success") {
              // Set the updated value in the formik state
              formik.setFieldValue("state", firstResult.PostOffice[0].State);
              formik.setFieldValue("city", firstResult.PostOffice[0].District);
              setState(true);
            } else {
              // Handle if the pincode lookup is not successful
              console.log("Pincode lookup failed");
              setState(false);
            }
          } else {
            // Handle if the response data is not valid or empty
            console.log("Invalid API response");
            setState(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          // Handle any errors that occurred during the API call
          console.error("Error fetching pincode details", error);
          setLoading(false);
          setState(false);
        });

      axios
        .get(
          `https://t5i5vqulse.execute-api.ap-south-1.amazonaws.com/api/deliverydate/${pincode}`
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const firstResult = response.data;
            console.log(firstResult);
            setCourier(firstResult[0]);
            setEDD(firstResult[1]);
          }
          setLoadingEDD(false);
        })
        .catch((error) => {
          // Handle any errors that occurred during the API call
          console.error("Error fetching delivery details", error);
          setLoadingEDD(false);
        });
    }
  };

  const handlePhoneChange = (event) => {
    const phone = event.target.value;
    // Implement your logic to update the target field based on the source value
    if (phone.length === 10) {
      setLoadingPhone(true);
      setTotalOrders(null);
      setLatestOrder(null);
      console.log("searxhing");
      axios
        .get(
          `https://t5i5vqulse.execute-api.ap-south-1.amazonaws.com/api/customerdetails/${phone}`
        )
        .then((response) => {
          if (response.data && response.data["success"] === "true") {
            const result = response.data;
            formik.setFieldValue("name", result.name);
            formik.setFieldValue("email", result.email);
            formik.setFieldValue("pincode", result.pincode);
            handleSourceChange({ target: { value: result.pincode } });
            formik.setFieldValue("address", result.address);
            formik.setFieldValue("city", result.city);
            setTotalOrders(result.totalOrders);
            setLatestOrder(result.ordernumber);
          }
          setLoadingPhone(false);
        })
        .catch((error) => {
          // Handle any errors that occurred during the API call
          console.error("Error fetching delivery details", error);
          setLoadingPhone(false);
        });
    }
  };

  useEffect(() => {
    // Listen for changes in authentication state
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUsers(user);
      } else {
        // User is signed out
        setUsers(null);
      }
    });
  }, []);

  useEffect(() => {
    calcTotals(
      formik.values.product1,
      formik.values.product2,
      formik.values.product3,
      formik.values.product1Quantity,
      formik.values.product2Quantity,
      formik.values.product3Quantity
    );
  }, [
    formik.values.product1,
    formik.values.product2,
    formik.values.product3,
    formik.values.product1Quantity,
    formik.values.product2Quantity,
    formik.values.product3Quantity,
    calcTotals,
  ]);

  const provider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log(token);
        // The signed-in user info.
        setUsers(result.user);
        // IdP data available using getAdditionalUserInfo(result)
      })
      .catch((error) => {
        console.error(error);
        // ...
      });
  };

  const signUserOut = async () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <div className="app">
      <div className="container">
        <img
          className="logo"
          src="https://www.dharishahayurveda.com/wp-content/uploads/2021/04/Kheera-Gel-04-1.svg"
          alt="SVG Logo"
        />
      </div>
      {users ? (
        <>
          <div className="container">
            <div className="separator"></div>
            <label>{users.displayName}</label>
            <span className="button-text">{users.email}</span>
            <button className="signout-button" onClick={signUserOut}>
              <span className="button-text">Sign Out</span>
            </button>
            <div className="separator"></div>
          </div>
          <div className="container">
            <span className="heading">Customer Details</span>
          </div>
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number*</label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                error={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? "true"
                    : "false"
                }
                onChange={(e) => {
                  formik.handleChange(e);
                  handlePhoneChange(e);
                }}
                value={formik.values.phoneNumber}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="error-message">{formik.errors.phoneNumber}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                required={true}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="error-message">{formik.errors.name}</div>
              )}
            </div>

            {loadingPhone && (
              <div className="form-group">
                <div className="spinner"></div>
              </div>
            )}
            {totalOrders && (
              <div className="row-group">
                <div className="form-group">
                  <label htmlFor="latestorder">Latest Order</label>
                  <span>{latestOrder}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="totalorders">Total Orders Placed</label>
                  <span>{totalOrders}</span>
                </div>
              </div>
            )}

            <div className="form-group">
              <label htmlFor="address">Address*</label>
              <textarea
                type="text"
                id="address"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
              {formik.touched.address && formik.errors.address && (
                <div className="error-message">{formik.errors.address}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="pincode">Pincode*</label>
              <input
                type="text"
                id="pincode"
                name="pincode"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleSourceChange(e);
                }}
                value={formik.values.pincode}
              />
              {formik.touched.pincode && formik.errors.pincode && (
                <div className="error-message">{formik.errors.pincode}</div>
              )}
            </div>
            {loading && loadingEDD && (
              <div className="form-group">
                <div className="spinner"></div>
              </div>
            )}
            {courier && edd && (
              <div className="row-group">
                <div className="form-group">
                  <label htmlFor="courier">Courier</label>
                  <span>{courier}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="edd">Delivery Date</label>
                  <span>{edd}</span>
                </div>
              </div>
            )}

            <div className="form-group">
              <label htmlFor="city">City*</label>
              <input
                type="text"
                id="city"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
              />
              {formik.touched.city && formik.errors.city && (
                <div className="error-message">{formik.errors.city}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="state">State*</label>
              <input
                type="text"
                id="state"
                name="state"
                onChange={formik.handleChange}
                value={formik.values.state}
                disabled={state}
              />
              {formik.touched.state && formik.errors.state && (
                <div className="error-message">{formik.errors.state}</div>
              )}
            </div>

            <div className="container">
              <div className="separator"></div>
            </div>
            <div className="container">
              <span className="heading">Optional Fields</span>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email ID</label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error-message">{formik.errors.email}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="secondaryPhoneNumber">
                Secondary Phone Number
              </label>
              <input
                type="text"
                id="secondaryPhoneNumber"
                name="secondaryPhoneNumber"
                onChange={formik.handleChange}
                value={formik.values.secondaryPhoneNumber}
              />
              {formik.touched.secondaryPhoneNumber &&
                formik.errors.secondaryPhoneNumber && (
                  <div className="error-message">
                    {formik.errors.secondaryPhoneNumber}
                  </div>
                )}
            </div>

            <div className="form-group">
              <label htmlFor="couponCode">Coupon Code</label>
              <input
                type="text"
                id="couponCode"
                name="couponCode"
                onChange={formik.handleChange}
                value={formik.values.couponCode}
              />
            </div>

            <div className="container">
              <div className="separator"></div>
            </div>

            <div className="container">
              <span className="heading">Order Details</span>
            </div>

            <div className="form-group">
              <label htmlFor="paymentMode">Payment Mode*</label>
              <select
                id="paymentMode"
                name="paymentMode"
                onChange={formik.handleChange}
                value={formik.values.paymentMode}
              >
                <option value="COD">COD</option>
                <option value="prepaid">Prepaid</option>
              </select>
              {formik.touched.paymentMode && formik.errors.paymentMode && (
                <div className="error-message">{formik.errors.paymentMode}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="orderBy">Order By*</label>
              <select
                id="orderBy"
                name="orderBy"
                onChange={formik.handleChange}
                value={formik.values.orderBy}
              >
                <option value="" disabled>
                  Select order by
                </option>
                {Object.keys(agentNames).map((key) => (
                  <option key={key} value={key}>
                    {agentNames[key]}
                  </option>
                ))}
                {/* <option value="ritu">Ritu</option>
              <option value="manpreet">Manpreet</option> */}
              </select>
              {formik.touched.orderBy && formik.errors.orderBy && (
                <div className="error-message">{formik.errors.orderBy}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="product1">Product 1*</label>
              <select
                id="product1"
                name="product1"
                onChange={formik.handleChange}
                value={formik.values.product1}
              >
                <option value="">Select product</option>
                {Object.keys(products).map((key) => (
                  <option key={key} value={key}>
                    {products[key]["name"]}
                  </option>
                ))}
              </select>
              {formik.touched.product1 && formik.errors.product1 && (
                <div className="error-message">{formik.errors.product1}</div>
              )}
            </div>

            {formik.values.product1 && (
              <>
                <div className="form-group">
                  <label htmlFor="product1Quantity">Product 1 Quantity*</label>
                  <input
                    type="number"
                    id="product1Quantity"
                    name="product1Quantity"
                    onChange={formik.handleChange}
                    value={formik.values.product1Quantity}
                  />
                  {formik.touched.product1Quantity &&
                    formik.errors.product1Quantity && (
                      <div className="error-message">
                        {formik.errors.product1Quantity}
                      </div>
                    )}
                </div>

                <div className="form-group">
                  <label htmlFor="product2">Product 2</label>
                  <select
                    id="product2"
                    name="product2"
                    onChange={formik.handleChange}
                    value={formik.values.product2}
                  >
                    <option value="">Select product</option>
                    {Object.keys(products).map((key) => (
                      <option key={key} value={key}>
                        {products[key]["name"]}
                      </option>
                    ))}
                  </select>
                  {formik.touched.product2 && formik.errors.product2 && (
                    <div className="error-message">
                      {formik.errors.product2}
                    </div>
                  )}
                </div>

                {formik.values.product2 && (
                  <>
                    <div className="form-group">
                      <label htmlFor="product2Quantity">
                        Product 2 Quantity
                      </label>
                      <input
                        type="number"
                        id="product2Quantity"
                        name="product2Quantity"
                        onChange={formik.handleChange}
                        value={formik.values.product2Quantity}
                      />
                      {formik.touched.product2Quantity &&
                        formik.errors.product2Quantity && (
                          <div className="error-message">
                            {formik.errors.product2Quantity}
                          </div>
                        )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="product3">Product 3</label>
                      <select
                        id="product3"
                        name="product3"
                        onChange={formik.handleChange}
                        value={formik.values.product3}
                      >
                        <option value="">Select product</option>
                        {Object.keys(products).map((key) => (
                          <option key={key} value={key}>
                            {products[key]["name"]}
                          </option>
                        ))}
                      </select>
                      {formik.touched.product3 && formik.errors.product3 && (
                        <div className="error-message">
                          {formik.errors.product3}
                        </div>
                      )}
                    </div>

                    {formik.values.product3 && (
                      <div className="form-group">
                        <label htmlFor="product3Quantity">
                          Product 3 Quantity
                        </label>
                        <input
                          type="number"
                          id="product3Quantity"
                          name="product3Quantity"
                          onChange={formik.handleChange}
                          value={formik.values.product3Quantity}
                        />
                        {formik.touched.product3Quantity &&
                          formik.errors.product3Quantity && (
                            <div className="error-message">
                              {formik.errors.product3Quantity}
                            </div>
                          )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {total ? (
              <div className="row-group">
                <div className="form-group">
                  <label htmlFor="total">Total</label>
                  <span>{total}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="pretotal">Prepaid Total</label>
                  <span>{prepaidTotal}</span>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="container">
              <div className="separator"></div>
            </div>

            <div className="container">
              <button
                type="submit"
                className="submit-button"
                disabled={loadingOrder}
                onClick={(event) => {
                  // event.preventDefault(); // Prevent default form submission
                  !formik.errors && handleSubmit(formik.values);
                }}
              >
                Submit
              </button>
            </div>
            {loadingOrder && (
              <div className="form-group">
                <div className="spinner"></div>
              </div>
            )}
            {orderNumber && (
              <div className="container">
                <span className="heading">Congrats on the sale 🎉!</span>
                <span className="subheading">
                  Order Number: <b>{orderNumber}</b>
                </span>
              </div>
            )}
            {orderError && (
              <div className="container">
                <span className="error-message">{orderError}</span>
              </div>
            )}
          </form>
        </>
      ) : (
        <div className="container">
          <button className="google-button" onClick={signInWithGoogle}>
            <img
              src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-1024.png"
              alt="Google Logo"
              className="google-icon"
            />
            <span className="button-text">Sign in with Google</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default App;
